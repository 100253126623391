<template>
  <div class="my-info">
    <Nav></Nav>
    <div class="info-con">
      <div class="info-title">
        您的位置：首页>>个人信息<span class="red" @click="onUpdata"
          >(修改个人信息)</span
        >
        <span class="red" @click="onPassWord"> (修改密码)</span
        ><span class="red" @click="onBindMobile"> (绑定手机)</span>
        <span class="red" @click="onBindChange">(余额变化记录)</span>
      </div>
      <div class="info-detail">
        <div class="info-left">
          <div class="info image">
            <div class="image-title">登录者头像：</div>
            <div
              class="image-url"
              :style="{
                backgroundImage: `url(` + myInfo.imageUrl + `)`,
              }"
              @click="onEnlargePic"
            ></div>
            <el-button class="up">
              <el-upload
                class="upload-demo"
                :action="imgUploadURL"
                :with-credentials="true"
                :on-success="onUploadImg"
                :show-file-list="false"
                :multiple="true"
                >上传图片</el-upload
              >
            </el-button>
          </div>
          <div class="info" v-if="myInfo.approveStatus != 'APPROVED'">
            <span>当前状态：</span
            ><span class="red">{{ myInfo.approveStatusName }}</span>
          </div>
          <div class="info" v-if="myInfo.approveStatus == 'REFUSED'">
            <span>拒绝原因：</span>{{ myInfo.refusedReason }}
          </div>
          <div class="info"><span>登录名：</span>{{ myInfo.loginName }}</div>
          <div class="info"><span>姓名：</span>{{ myInfo.chnName }}</div>
          <div class="info"><span>职务：</span>{{ myInfo.head }}</div>
          <div class="info">
            <span>默认笔名：</span>{{ myInfo.defaultAuthorName }}
          </div>
          <div class="info"><span>地址：</span>{{ myInfo.address }}</div>
          <div class="info"><span>固定电话：</span>{{ myInfo.telephone }}</div>
          <div class="info"><span>邮箱:</span>{{ myInfo.email }}</div>
          <div class="info"><span>创建时间：</span>{{ myInfo.createTime }}</div>
          <div class="info multi"><span>简介:</span>{{ myInfo.comment }}</div>
          <div class="info"><span>角色名：</span>{{ myInfo.roleName }}</div>
          <div class="info"><span>上传权限：</span>{{ myInfo.address }}</div>
          <div class="info" v-if="myInfo.lastLogin">
            <span>当前IP:</span>{{ myInfo.lastLogin.ip }}
          </div>
        </div>
        <div class="info-right">
          <div class="info"><span>工作单位：</span>{{ myInfo.company }}</div>
          <div class="info"><span>邮编：</span>{{ myInfo.postCode }}</div>
          <template v-if="myInfo.roleType == 'DOWNLOADER'">
            <div class="info">
              <span>用户余额：</span>{{ myInfo.credit }}元
              <el-button
                v-if="selfCharge"
                class="action"
                @click="onSelfChargeClick"
                >充值</el-button
              >
            </div>
            <div class="info">
              <span>余额支付折扣：</span>{{ myInfo.extra.virtualPayRate }}
            </div>
          </template>
          <div class="info">
            <span>移动电话：</span>{{ myInfo.mobile
            }}<i class="red">{{
              myInfo.mobileConfirmed ? "（已绑定）" : "（未绑定）"
            }}</i>
          </div>
          <div class="info" v-if="myInfo.lastLogin">
            <span>当前登录时间：</span>{{ myInfo.lastLogin.time }}
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="selfChargeDialogShow"
      title="输入充值数额，单位元"
    >
      <div class="charge-dialog-body">
        <el-input
          v-model="selfChargeValue"
          v-if="!selfChargePayURLShow"
          placeholder="输入充值数额，单位元"
        ></el-input>
      </div>
      <div slot="footer" class="charge-dialog-footer">
        <el-button @click="selfChargeDialogShow = false">取 消</el-button>
        <el-button type="primary" @click="confirmSelfCharge"
          >确定充值</el-button
        >
      </div>
    </el-dialog>
    <el-dialog :visible.sync="selfChargePayURLShow" title="扫码完成充值">
      <div class="charge-dialog-body">
        <span>扫码充值{{ selfChargeValue }}元</span>
        <div
          class="pay-url"
          v-if="selfChargePayURLShow"
          :style="{ backgroundImage: 'url(' + payURL + ')' }"
        ></div>
      </div>
      <div slot="footer" class="charge-dialog-footer">
        <el-button @click="selfChargePayURLShow = false">取 消</el-button>
        <el-button type="primary" @click="finishSelfCharge">完成充值</el-button>
      </div>
    </el-dialog>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  name: "MyInfo",
  components: { Nav, Bottom },
  data: function () {
    return {
      myInfo: {}, // 个人信息
      imgUploadURL: "",
      selfCharge: false,
      selfChargeDialogShow: false,
      selfChargeValue: 0,
      selfChargePayURLShow: false,
      payURL: "",
    };
  },
  created() {
    var thiz = this;
    thiz.init();
  },
  methods: {
    init() {
      let thiz = this;
      RD.pure()
        .user()
        .sync()
        .then((data) => {
          data.imageUrl =
            RD.user().headPic_URL() + "?_=" + new Date().getTime();
          thiz.myInfo = data;
          if (
            data.noStoreRights.indexOf("CREDIT_PAY") != -1 &&
            data.noStoreRights.indexOf("SELF_CHARGE") != -1
          ) {
            thiz.selfCharge = true;
          }
        })
        .catch((error) => {
          console.log("err", error);
          if (error.code == "-1") {
            thiz.$alert("需要登录").then(() => {
              thiz.$router.push("/picture.html");
            });
          }
        });
      thiz.imgUploadURL = RD.user().uploadHeadPic_URL();
    },
    finishSelfCharge() {
      RD.user()
        .checkSelfCharge()
        .then(() => {
          this.init();
          this.selfChargeDialogShow = false;
          this.selfChargePayURLShow = false;
        });
    },
    confirmSelfCharge() {
      var chargeValue = parseFloat(this.selfChargeValue);
      if (isNaN(chargeValue)) {
        this.$alert("输出的数额不合法");
        return;
      }
      if (chargeValue < 0) {
        this.$alert("输出的数额不合法");
        return;
      }
      RD.user()
        .selfCharge(chargeValue)
        .then((url) => {
          this.payURL = url;
          this.selfChargePayURLShow = true;
          this.selfChargeDialogShow = false;
        });
    },
    onSelfChargeClick() {
      let thiz = this;
      thiz.selfChargeDialogShow = true;
      thiz.selfChargePayURLShow = false;
    },
    // 修改信息
    onUpdata() {
      var thiz = this;
      thiz.$router.push("/updataInfo.html");
    },
    // 修改密码
    onPassWord() {
      var thiz = this;
      thiz.$router.push("/resetPassword.html");
    },
    // 绑定手机
    onBindMobile() {
      var thiz = this;
      thiz.$router.push("/bindMobile.html");
    },
    // 余额变化记录
    onBindChange() {
      var thiz = this;
      thiz.$router.push("/bindShopChange.html");
    },
    // 更换头像成功后
    onUploadImg(res, file) {
      console.log("46", file);
      var thiz = this;
      if (res.code == 0) {
        thiz.myInfo.imageUrl =
          RD.user().headPic_URL() + "?_=" + new Date().getTime();
      } else {
        alert(res.msg);
      }
    },
    onEnlargePic() {
      console.log("kl");
    },
  },
};
</script>
<style scoped lang="less">
.red {
  color: red;
}
.my-info {
  .info-con {
    width: 120rem;
    margin: 0 auto;
    min-height: calc(100vh - 48.9rem);
    .info-title {
      height: 4rem;
      background-color: #ccc;
      font-size: 1.3rem;
      line-height: 4.5rem;
      text-align: left;
      span {
        cursor: pointer;
      }
    }
    .info-detail {
      // min-height: calc(100vh - 36.7rem);
      background-color: #dfdfdf;
      display: flex;
      .info-left,
      .info-right {
        flex: 1;
        margin-left: 2rem;
        .info {
          line-height: 3rem;
          text-align: left;
          font-size: 1.2rem;
          font-weight: bold;
          span {
            display: inline-block;
            width: 10rem;
          }
          .action {
            margin-left: 3rem;
          }
        }
        .multi.info {
          white-space: pre-wrap;
        }
        .image.info {
          height: 13rem;
          width: 100%;
          display: flex;
          margin: 1rem 0rem;
          .image-title {
            width: 10rem;
            height: 100%;
          }
          .image-url {
            width: 12rem;
            height: 12rem;
            border-radius: 6rem;
            background: no-repeat 50% / cover;
            border: 0.1rem solid #ccc;
            margin-left: 3rem;
          }
          .up {
            margin-top: 2rem;
            margin-left: 2rem;
            max-height: 4.5rem;
          }
        }
      }
    }
  }
}
@media all and (max-width: 800px) {
  .my-info {
    .info-con {
      width: 750px;
      .info-title {
        height: 25px;
        font-size: 10px;
        line-height: 25px;
      }
      .info-detail {
        min-height: calc(100vh - 261px);

        .info-left,
        .info-right {
          margin-left: 10px;
          .info {
            line-height: 20px;
            font-size: 12px;
            span {
              width: 80px;
            }
            .action {
              margin-left: 20px;
            }
          }
          .image.info {
            height: 80px;
            margin: 5px 0rem;
            .image-title {
              width: 60px;
            }
            .image-url {
              width: 70px;
              height: 70px;
              border-radius: 35px;
              border: 1px solid #ccc;
              margin-left: 20px;
            }
            .up {
              margin-top: 10px;
              margin-left: 10px;
              max-height: 40px;
            }
          }
        }
      }
    }
  }
}

.charge-dialog-body {
  display: flex;
  align-items: center;
  flex-direction: column;
  .pay-url {
    width: 300px;
    height: 300px;
    background: no-repeat 50% / contain;
  }
}
</style>
